<template>
  <div>
    <div class="relative bg-yellow-100 bg-opacity-50">
      <img
        class="sm:hidden w-full"
        :src="`/img/${topic.smallImage}`"
        :alt="topic.label"
      />
      <div
        class="absolute inset-0 flex sm:items-center mt-4 sm:mt-0 justify-center"
      >
        <h2 class="text-center font-bold text-3xl text-primary-orange">
          {{ topic.label }}
        </h2>
      </div>
      <img
        class="hidden sm:block w-full"
        :src="`/img/${topic.bigImage}`"
        :alt="topic.label"
      />
    </div>
    <div class="px-6 md:px-0 py-12 md:max-w-3xl md:mx-auto">
      <MultiSearch />
    </div>
    <!-- <div class="px-12 md:max-w-4xl md:mx-auto">
      <ul class="list-disc space-y-4">
        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit?</li>
        <li>
          Laudantium vel aut, distinctio quos, quia accusantium consequuntur
          perferendis voluptates.
        </li>
        <li>
          Amet quae voluptatem commodi laboriosam, distinctio, vero quo in
          dolorem qui ratione ab animi.
        </li>
        <li>
          Iure eligendi dolores corrupti eveniet blanditiis ipsum, nisi commodi
          dignissimos provident.
        </li>
        <li>
          Odit aliquam rem, inventore eius, voluptas ea optio hic quod saepe
          aliquid sed laborum tempora sint! Odit velit quod, dolorem.
        </li>
        <li>
          Ipsam fugiat error sed perferendis sint sit dolores enim id, numquam
          eaque reiciendis provident iure velit obcaecati recusandae vel
          sapiente.
        </li>
      </ul>
    </div> -->

    <div class="py-12 px-6 md:px-0 md:max-w-2xl md:mx-auto">
      <h2 class="mt-3 text-3xl font-semibold text-primary-orange text-center">
        Still Need Help?
      </h2>
      <!-- <div class="mt-5 pt-4 w-48 mx-auto border-t border-primary-orange"></div>
      <p class="text-center text-sm">
        Didn't find what you are looking for? Our Customer Care Specialists are
        also avialable to support you. Call our toll-free hotline number or
        message us through our social media.
      </p>
      <div
        class="mt-6 flex flex-col md:flex-row items-center justify-center md:space-x-6"
      >
        <div
          class="bg-primary-orange w-64 flex items-center justify-center space-x-4 rounded-lg shadow"
        >
          <p
            class="uppercase text-gray-900 font-bold text-center leading-tight"
          >
            Toll <br />
            Free
          </p>
          <p class="font-bold text-white text-2xl py-4">1 800 XX XX</p>
        </div>
        <div class="flex justify-center">
          <div class="text-xl space-x-2 py-6 md:py-0">
            <a
              class="inline-flex items-center justify-center h-10 w-10 bg-primary-orange text-white rounded-full"
              href="#"
              target="_blank"
              ><i class="fab fa-facebook-f"></i
            ></a>
            <a
              class="inline-flex items-center justify-center h-10 w-10 bg-primary-orange text-white rounded-full"
              href="#"
              target="_blank"
              ><i class="fab fa-twitter"></i
            ></a>
            <a
              class="inline-flex items-center justify-center h-10 w-10 bg-primary-orange text-white rounded-full"
              href="#"
              target="_blank"
              ><i class="fab fa-linkedin-in"></i
            ></a>
            <a
              class="inline-flex items-center justify-center h-10 w-10 bg-primary-orange text-white rounded-full"
              href="#"
              target="_blank"
              ><i class="fab fa-whatsapp"></i
            ></a>
            <a
              class="inline-flex items-center justify-center h-10 w-10 bg-primary-orange text-white rounded-full"
              href="#"
              target="_blank"
              ><i class="fab fa-instagram"></i
            ></a>
          </div>
        </div>
      </div> -->
      <div class="md:pt-6 text-center px-6 md:px-12 space-y-6">
        <!-- <p class="font-semibold uppercase text-secondary-gray">or</p> -->
        <Button @click="$router.push('/question')" class="w-full sm:w-auto"
          >Submit a Question</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Topics from "@/mixins/Topics";
import Button from "@/components/Button";
import MultiSearch from "@/components/MultiSearch";

export default {
  mixins: [Topics],
  components: {
    Button,
    MultiSearch,
  },
  data: () => ({}),
  created() {},
  computed: {
    topic() {
      return this.topics.find(topic => topic.path === this.$route.params.topic);
    },
  },
  methods: {},
};
</script>

<style></style>
